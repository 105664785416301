.featureCard {
    background: #fff;
    padding: 30px;
    border: 1px solid #eee;
    width: 100%;
    transition: 1s;
    display: flex;
}

.featureCard5 {
    padding-bottom: 3.3rem;
}

.featureCard:hover {
    background-image: url('../../images/cardBg1.jpg');
    background-size: cover;
    box-shadow: 0px 0px 25px 0px rgb(95 95 95 / 24%);
    -moz-box-shadow: 0px 0px 25px 0px rgba(95, 95, 95, 0.24);
}

.featureCard .featureImgCard{
    margin-right: 1.5rem;
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    transition: 0.8s;
    background: #ffdede;
    border: 2px solid #F95252;
}
.featureCard:hover .featureImgCard {
    background: #F95252; 
}

.featureCard .featureCardImg1 {
    display: block;
    transition: 0.8s;
}
.featureCard .featureCardImg2 {
    display: none;
    transition: 0.8s;
}
.featureCard:hover .featureCardImg1 {
    display: none;
}
.featureCard:hover .featureCardImg2 {
    display: block;
}

.featureCard .featureImgCard img {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.featureCard h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.featureCard p {
    font-size: 16px;
}

@media (max-width: 768px){
    .featureCard .featureImgCard{
        height: 70px;
        width: 70px;
    }
    .featureCard {
        display: block;
        padding: 20px;
    }
    .featureCard .featureImgCard {
        margin: 0;
    }
    
    .featureCard h4 {
       text-align: left;
       margin: 1rem 0;
       font-size: 18px;
    }
    
    .featureCard p {
       font-size: 14px;
    }
}