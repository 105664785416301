#partner {
    padding: 5rem 0;
    /* background: linear-gradient(to right, #F95252, #ff5050) */
    /* background: linear-gradient(to right, #da4453, #89216b); */
    background: linear-gradient(to left, #e53935, #e35d5b);
}

.partnerHeader {
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}
.partnerP {
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2rem;
}

.partnerCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 1.2rem;
    justify-content: center;
}

.partnerCard {
    margin-bottom: 20px;
    color: #fff;
    padding: 2rem 2rem 2.2rem;
    transition: 0.4s;
    border: 1px solid #b94343;
}

.partnerCard:hover {
    border: 1px solid transparent;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.partnerImgCard {
    margin: 0;
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    transition: .3s;
    border: 2px solid transparent;
}

.partnerCard h4 {
    font-size: 18px;
    font-weight: bold;
}

.partnerImgCard img {
    margin-left: -25px;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: 0.7s;
}

@media (max-width: 992px){
    .partnerCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        padding: 0.8rem;
    }
    .partnerHeader {  
        font-size: 25px;
    }
    .partnerP { 
        font-size: 18px;
        margin-bottom: 1.5rem;
    }
    #partner {
        padding: 2.5rem 0;
    }
    
}

@media (max-width: 768px){
    .partnerCards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        padding: 0.5rem 1.4rem;
    }

    .partnerHeader {
        font-size: 23px;
        text-align: left;
        margin-left: 20px;
    }
    .partnerP {
        color: #fff;
        font-size: 16px;
        text-align: left;
        margin: 0 20px 1.3rem;
    }
}