.navbar-toggler:focus {
    box-shadow: none;
}

.navLogo {
    height:36px;
    margin-top: 0.7rem;
}
.navbar-nav{
    margin-top: 0.4rem;;
}

.navbar-nav a {
    color: #fff!important;
    transition: 1s;
    font-size: 20px;
    margin: 0 1rem;
}

.navbar-nav a:hover {
    color: #F95252!important;
}

#nav-icon div {
    width: 18px;
    height: 2px;
    background-color:#F95252;
    margin: 3px 0;
}


/* Media Query */

@media (max-width: 982px){

    .navLogo {
        height: 40px;
        margin-top: 5px;
    }
    .navbar-nav {
        text-align: center;
    }
    .navbar-nav a {
        font-size: 18px;
    }
}

@media (max-width: 768px){
    .navLogo {
        height: 30px;
        margin-left: 1.2rem;
    }
    .navbar-nav a {
        font-size: 16px;
    }
}