#sustainability {
    padding: 5rem 0;
    background-image: url('../../images//treeBg.png'), url('../../images//treeBg.png'), url('../../images//treeBg.png');
    background-position: bottom center, right top, left top;
    background-repeat: no-repeat;
    background-size: 16%, 16%, 16%;
}

.sustainableCard {
    text-align: center;
    background: white;
    padding: 1.5rem 0 2rem;
    border-radius: 0.3rem;
    box-shadow: 1px 0 20px rgb(0 0 0 / 10%);
}

.sustainableCard h5 {
    margin-bottom: 1.5rem;
    font-size: 20px;
}

@media (max-width: 992px){
    #sustainability {
        padding: 3rem 0;
    }
    .sustainableCard{
        margin: 0 1.4rem;
    }
    .sustainableCard h5 {
        margin-bottom: 1.2rem;
        font-size: 18px;
    }
}