#footer {
    padding: 5rem 0 3rem;
    background-color: #FFE1E1;
}

#footer img {
    height: 100px;
}

.address {
    display: flex;
    justify-content: center;
}

.footerDetails h6 {
    font-weight: 600;
    margin: 0 1rem 0.5rem;
}

.footerDetails p {
    margin: 0 1rem 0;
}
.contactDetails {
    text-align: center;
}
.contactDetails span {
    font-size: 16px;
    display: block;
    margin: 0.8rem 0;
    font-weight: 500;
}
.pageLinks {
    display: flex;
    justify-content: end;
}
.pageLinks a {
    font-size: 1rem;
    font-weight: 500;
    margin: 0.8rem 0;
    color: #000;
    display: block;
    text-decoration: none;
    text-align: left;
}

.allSocialIcons {
    margin: 1.5rem 0;
    text-align: center;
}

.allSocialIcons a {
    color: #565656;
    font-size: 20px;
    margin: 0 10px;
    transition: 1s;
}

.allSocialIcons a:hover {
    color: #FF2D30;
}

.copyright {
    background: #FFE1E1;
}

.copyright p {
    padding: 1rem 0;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0;
}

@media (max-width: 992px){
    .contactDetails span {       
        margin: 0.4rem 0;
    }
    #footer {
        padding: 2.5rem 0 2rem;
    }
    
    .pageLinks {
        justify-content: start;
        margin-top: 1rem;
    }
    .pageLinks a {
        margin: 0.4rem 0;
    }
    #footer img {
        height: 70px;
    }
    .allSocialIcons {
        margin: 1rem 0;
    }
    .copyright p {
        padding: 0.5rem;
    }
}

@media (max-width: 768px){
    #footer {
        padding: 2rem 0 1rem;
    }
    .footerDetails h6{
        margin: 16px 0 0 20px;
        font-size: 14px;
    }
    .address {
        display: block;

    }
    .address p {
        margin: 10px 0 0 20px;
        text-align: start;
        font-size: 14px;
        padding-right: 20%;
    }
    .pageLinks {
        justify-content: center;
        margin-top: 0;
    }
    #footer img {
        height: 50px;
        margin-left: 20px;
    }
    .contactDetails{
        text-align: left;
        margin-left: 20px;
    }
    .pageLinks {
        justify-content: start;
        margin-left: 20px;
    }
    .allSocialIcons {
        text-align: left;
        margin-left: 10px;
    }
    .contactDetails span {
        font-size: 14px;
    }
    .pageLinks a {
        font-size: 14px;
    }
    .allSocialIcons a {
        font-size: 16px;
    }
    .copyright p {
        text-align: left;
        font-size: 12px;
        padding-left: 30px;
    }
}