.terms {
    padding: 2rem 4rem;
}

.terms h2 {
    text-align: center;
    font-weight: bold;
    margin: 2rem 0 3rem;
}

.terms h3 {
    font-size: 1.2rem;
    font-weight: bold;
}

@media (max-width: 768px){
    .terms h2 {
        font-size: 1.5rem;
        margin: 1.5rem 0 2rem;
    }
    .terms {
        padding: 2rem;
    }
    .terms h3 {
        font-size: 1rem;
        font-weight: bold;
    }
    
}