.fullHeader {
    background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../images/headerBg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    color: #fff;
}

.header {
    height: 100%;
    margin-top: 12%;
}

.headerContentBox {
    text-align: center;
}

.headerContentBox h2{
    font-size: 46px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 20px;
}

.headerContentBox p {
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
    margin: 2rem 0 2rem;
}

.headerContentBox button {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.8rem 4.2rem;
    border: 0;
    outline: none;
    color: #fff;
    background: #000000;
    transition: 0.6s;
    cursor: pointer;
    border-radius: 30px;
    border: 3px solid transparent;
    text-transform: uppercase;
}

.headerContentBox button:hover{
    background: #F95252;
}

.headerImgBox {
    padding: 0 2rem;
}

.headerImage {
    width: 100%;
}

/* Media Query */

@media (max-width: 992px) {
    .header {
        height: 100%;
        margin-top: 14%;
    }
    .fullHeader {
        height: 100%;
        padding: 0 0 4rem;
    }
    .headerContentBox h2{
        font-size: 35px;
        line-height: 34px;
        margin: 3rem 0 10px;
        padding: 0 0.8rem;
    }
    .headerContentBox p {
        font-size: 20px;
        line-height: 18px;
        margin: 1.8rem 0;
        padding: 0 0.8rem;
    }

    .headerContentBox button {
        font-size: 1rem;
        padding: 0.5rem 2.5rem;
    }
}


@media (max-width: 768px) {
    .headerContentBox {
        text-align: left;
    }
    .headerContentBox h2{
        font-size: 23px;
        line-height: 2;
        margin-bottom: 10px;
        padding: 0 31px;
    }
    .headerContentBox p {
        font-size: 18px;
        line-height: 2;
        margin: 1.5rem 0;
        padding: 0 31px;
    }
    .headerContentBox button {
        font-size: 15px;
        padding: 0.4rem 2.2rem;
        margin-left: 31px;
    }
}
