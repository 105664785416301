#contact {
    padding: 5rem 0;
    background: rgb(247 247 247 / 60%);
}

#contact .contactImg {
    padding: 2rem 0;
    text-align: center;
}

#contact .contactImg img {
    height: 400px;
    margin-left: 1.5rem;
}

.form-control-lg {
    font-size: 1rem;
}

.form-control:focus {
    border-color: #ffafb0!important;
    box-shadow: 0 0 0 0.25rem rgba(255, 58, 68, 0.18)!important;
}

.submitBtn {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.8rem 5rem;
    margin-top: 1.3rem;
    font-weight: 400;
    border: 0;
    border-radius: 30px;
    outline: none;
    color: #fff;
    background: #F85252; 
    transition: 1s;
    cursor: pointer;
}

.submitBtn:hover{
    background: #e4372e;
}

.form-select {
    color: #747474;
    font-size: 1rem;
}

.commentBox {
    height: 135px;
}

@media (max-width: 762px){
    #contact .contactImg img {
        margin-left: 0;
    }
    #contact {
        padding: 2.5rem 0;
    }
    .submitBtn {
        font-size: 1rem;
        padding: 0.5rem 5rem;
        margin: 20px 20px;
    }
    .contactForm {
        padding: 0 1.4rem;
    }
    .form-control-lg {
        font-size: 14px;
    }
}