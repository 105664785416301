#services {
    padding: 6rem 0;
    background-color: #fff;
}

.secHeader {
    font-size: 35px;
    text-align: center;
    margin-bottom: 12px;
    font-weight: 1000;
    line-height: 30px;
    text-transform: capitalize;
}

.secP {
    font-size: 20px;
    text-align: center;
    margin-bottom: 3rem;
}

.featuredBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 0.8rem;
}

@media (max-width: 992px) {
    .featuredBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        padding: 0.8rem;
    }
    .secHeader {
        font-size: 25px;
    }
    .secP {
        font-size: 18px;
        margin-bottom: 1.5rem;
    }
    #services {
        padding: 3rem 0;
    }
}

@media (max-width: 768px) {
    .secHeader {
        text-align: left;
        margin-left: 20px;
        font-size: 23px;
    }
    .secP {
        text-align: left;
        font-size: 16px;
        margin: 0 23px 20px;
    }
    .featuredBox {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 0.5rem 1.4rem;
    }
}

